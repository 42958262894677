<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header ml-4">
            <signer-info :signer="signer"/>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="section-header">
                  <v-select v-model="signer"
                            :options="signers"
                            label="text"
                  >
                    <template slot="option" slot-scope="option" v-if="option">
                      <div v-html="option.text"></div>
                    </template>
                    <span slot="no-options">Нет данных</span>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignerInfo from "../../components/sign/signer-info"
import SIGN from "../../shared/sign/sign-document"

export default {
  name: "check-key",
  components: {
    SignerInfo
  },
  data() {
    return {
      loading: false,
      signers: [],
      signer: null
    }
  },
  methods: {

  },
  created() {
    SIGN.signInit()
    setTimeout(() => {
      this.signers = SIGN.singers()
    }, 1000)
  }
}
</script>

<style scoped>

</style>